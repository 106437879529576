<style scoped lang="less">
@viewport-width: 3.75;
* {
  box-sizing: border-box;
}
.home {
  width: 100%;
  min-width: 375px;
  //height: 2000px;
  background: #13111c;
  padding-bottom: 1px;
}
.top-module {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  @media screen and (max-width: 1306px) {
    padding: 0 calc(20 / @viewport-width * 1vw);
  }
  .join-us {
    margin-top: 32px;
    width: 226px;
    height: 70px;
    line-height: 70px;
    font-family: NT Somic, NT Somic, serif;
    font-weight: 500;
    font-size: 22px;
    color: rgba(255, 255, 255, 0.5);
    font-style: normal;
    text-transform: none;
    border-radius: 100px 100px 100px 100px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    //&::after {
    //
    //}
    .join-us-img {
      display: flex;
      align-items: center;
      a {
        width: 52px;
        height: 52px;
        img {
          vertical-align: top;
        }
      }
      a:first-child {
        margin: 0 4px 0 9px;
      }
      a:last-child {
        margin-right: 4px;
      }
    }
    @media screen and (max-width: 1306px) {
      margin-top: 16px;
      order: 2;
      width: 151px;
      height: 50px;
      line-height: 16px;
      font-size: 14px;
      .join-us-img {
        display: flex;
        align-items: center;
        a {
          width: 42px;
          height: 42px;
          img {
            vertical-align: top;
            width: 100%;
          }
        }
        a:first-child {
          margin: 4px 2px 4px 4px;
        }
        a:last-child {
          margin-right: 2px;
        }
      }
    }
  }
  .tiger-icon {
    @media screen and (max-width: 1306px) {
      flex: 0 0 100%;
      order: 1;
      text-align: center;
      img {
        width: calc(335 / @viewport-width * 1vw);
        max-width: 355px;
      }
    }
  }
  .log-white {
    display: flex;
    align-items: center;
    height: 59px;
    margin-top: 32px;
    @media screen and (max-width: 1306px) {
      order: 3;
      margin-top: 2px;
    }
    .log-pc {
      width: 90px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: rgba(255, 255, 255);
      background-color: #292a2c;
      border-radius: 15px;
      margin-top: 16px;
      margin-right: 20px;
      @media screen and (max-width: 1306px) {
        display: none;
      }
    }
  }
}
.log-mb {
  display: none;
  width: 90px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: rgba(255, 255, 255);
  //border: 2px solid rgba(255, 255, 255, 0.15);
  background-color: #292a2c;
  border-radius: 15px;
  margin-top: 16px;
  margin-right: 20px;
  @media screen and (max-width: 1306px) {
    display: block;
    margin: 10px auto;
  }
}
::v-deep .el-dialog {
  background-color: #14161c;
  width: 500px;
  height: 300px;
  border-radius: 40px;
  .el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    span {
      font-size: 20px;
      color: #ffffff;
    }
    input {
      margin-top: 30px;
      outline: none;
      width: 450px;
      height: 70px;
      color: rgba(255, 255, 255, 0.7);
      font-size: 20px;
      background: #1c1d22;
      border-radius: 10px;
      border: 0.1px solid rgba(255, 255, 255, 0.15);
      padding-left: 20px;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }
    div {
      margin-top: 10px;
      outline: none;
      width: 450px;
      height: 50px;
      color: #e9ffff;
      font-weight: 900;
      line-height: 50px;
      background: #369eff;
      border-radius: 10px;
    }
  }
}
.unleash-module {
  margin-top: 262px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1306px) {
    margin-top: 64px;
    padding: 0;
  }
  .unleash-left {
    @media screen and (max-width: 1306px) {
      display: none;
    }
    width: 300px;
    img:last-child {
      margin-left: 100px;
    }
  }
  .unleash-center {
    flex-grow: 1;
    width: 893px;
    font-family: NT Somic, NT Somic, serif;
    font-weight: bold;
    color: #ffffff;
    line-height: 84px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    h1 {
      font-size: 80px;
    }
    img:nth-of-type(2) {
      display: none;
    }
    img:nth-of-type(4) {
      display: none;
    }
    p {
      font-family: ABeeZee, ABeeZee, serif;
      font-weight: 400;
      font-size: 24px;
      color: rgba(255, 255, 255, 0.8);
      line-height: 40px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
    @media screen and (max-width: 1306px) {
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 335px;
      font-size: 48px;
      line-height: 54px;
      h1 {
        font-size: 48px;
      }
      img:first-of-type {
        display: none;
      }
      img:nth-of-type(2) {
        display: block;
        margin: 12px auto;
      }
      img:nth-of-type(3) {
        display: none;
      }
      img:nth-of-type(4) {
        display: block;
        width: 375px;
        margin: 0 auto;
      }
      p {
        font-family: NT Somic, NT Somic, serif;
        margin: 0 auto;
        width: 334px;
        font-size: 18px;
        line-height: 24px;
      }
      p:nth-of-type(2) {
        display: none;
      }
    }
  }
  .unleash-right {
    @media screen and (max-width: 1306px) {
      display: none;
    }
    width: 300px;
    img:first-child {
      margin-left: 100px;
    }
  }
}
.roadmap-module {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 421px;
  h1 {
    font-weight: bold;
    font-size: 80px;
    color: #ffffff;
    line-height: 84px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  ul {
    margin-top: 120px;
    width: 78.85%;
  }
  li {
    all: unset;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    min-height: 160px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.04) 100%
    );
    border-radius: 53px 53px 53px 53px;
    h1 {
      flex: 1;
      margin-left: 90px;
      //font-family: NT Somic, NT Somic, serif;
      font-weight: bold;
      font-size: 80px;
      color: rgba(255, 255, 255, 0.5);
      line-height: 84px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      .li-text {
        //font-family: NT Somic, NT Somic, serif;
        position: relative;
        top: 11px;
        margin-left: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        font-size: 24px;
        color: #ffffff;
        line-height: 32px;
        text-align: left;
        font-style: normal;
        text-transform: none;

        &::before {
          position: absolute;
          top: 12px;
          left: -18px;
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #ffffff;
          border-radius: 3px 3px 3px 3px;
        }
      }
    }
  }
  li:nth-child(-n + 4) {
    background: url("@/assets/pc/home/Background+VerticalBorder.png") no-repeat;
    background-size: 100% 100%;
  }
  .yan-jing {
    position: absolute;
    top: 176 + 84px;
    right: 7.5%;
  }
  .yi-fu {
    position: absolute;
    top: 860 + 280px;
    left: 28.13%;
  }
  @media screen and (max-width: 1306px) {
    margin-top: 108px;
    line-height: 44px;
    h1 {
      font-size: 40px;
    }
    ul {
      width: 335px;
      margin-top: 60px;
    }
    li {
      flex-direction: column;
      align-items: start;
      min-height: 176px;
      border-radius: 20px 20px 20px 20px;
      padding-left: 26px;
      padding-bottom: 21px;
      h1 {
        flex: 0;
        font-size: 36px;
        height: 40px;
        line-height: 40px;
        white-space: nowrap;
        margin: 21px 0 0 0;
      }
      div {
        font-size: 20px;
        line-height: 28px;
      }
    }
    li:nth-of-type(-n + 4) {
      //width: 335px;
      //height: 200px;
      background: url("@/assets/mb/home/Background.png");
      background-size: 100% 100%;
      border-radius: 20px 20px 20px 20px;
    }
    .yan-jing {
      display: none;
    }
    .yi-fu {
      display: none;
    }
  }
}
.link-module {
  display: flex;
  align-items: center;
  margin: 76px auto 48px;
  width: 138px;
  height: 70px;
  border-radius: 100px 100px 100px 100px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  @media screen and (max-width: 1306px) {
    border: 0;
  }
  img {
    width: 52px;
    height: 52px;
  }
  a:first-child {
    margin-left: 13px;
  }
  a:last-child {
    margin-left: 8px;
  }
}
</style>
<template>
  <div class="home">
    <div class="top-module">
      <div class="join-us">
        <div class="join-us-img">
          <a href="https://x.com/TigersCEO">
            <img src="@/assets/pc/home/X.png" alt="" />
          </a>
          <a href="https://t.me/Tigers_Komba_bot">
            <img src="@/assets/pc/home/telegram.png" alt="" />
          </a>
          Join us!
        </div>
      </div>
      <div class="tiger-icon">
        <img src="@/assets/pc/home/Border.png" alt="" />
      </div>
      <div class="log-white">
        <div class="log-pc" @click="dialogVisible = true">Log in</div>
        <a href="javascript:void(0);" class="white-paper">
          <!--        <img src="@/assets/pc/home/Button.png" alt="" />-->
          <img src="@/assets/mb/home/Button.png" alt="" />
        </a>
      </div>
    </div>
    <div class="log-mb" @click="dialogVisible = true">Log in</div>
    <el-dialog ref="dialogRef" v-model="dialogVisible" width="50%">
      <span>Log in or sign up</span>
      <input type="text" :placeholder="ph" @focus="rmPh" @blur="addPh" />
      <div>Continue</div>
    </el-dialog>
    <div class="unleash-module">
      <div class="unleash-left">
        <img src="@/assets/pc/home/T4.png" alt="" />
        <img src="@/assets/pc/home/T3.png" alt="" />
      </div>
      <div class="unleash-center">
        <h1>Unleash your inner CEO</h1>
        <img src="@/assets/pc/home/logo.png" alt="" />
        <img src="@/assets/mb/home/logo.png" alt="" />
        <p>
          Make your way from the shaved hamster to the grandmaster CEO of the
          tier-1 crypto exchange
        </p>
        <p>Buy upgrades, complete quests, invite friends and become the best</p>
        <a href="https://t.me/Tigers_Komba_bot">
          <img src="@/assets/pc/home/Play.png" alt="" />
          <img src="@/assets/mb/home/Play%20now.png" alt="" />
        </a>
      </div>
      <div class="unleash-right">
        <img src="@/assets/pc/home/T1.png" alt="" />
        <img src="@/assets/pc/home/T2.png" alt="" />
      </div>
    </div>
    <div class="roadmap-module">
      <h1>Roadmap</h1>
      <ul>
        <li>
          <h1>August 2024</h1>
          <div>
            <p class="li-text">Basic game</p>
            <p class="li-text">Mining updates</p>
            <p class="li-text">Earn tasks</p>
            <!--            <div class="li-text">Basic game</div>-->
          </div>
        </li>
        <li>
          <h1>September 2024</h1>
          <div>
            <p class="li-text">Referral system</p>
            <p class="li-text">LVL ratings</p>
            <p class="li-text">Daily rewards</p>
          </div>
        </li>
        <li>
          <h1>October 2024</h1>
          <div>
            <p class="li-text">Special cards</p>
            <p class="li-text">Daily Combo</p>
            <p class="li-text">«Partner» blockchain announcement</p>
          </div>
        </li>
        <li>
          <h1>November 2024</h1>
          <div>
            <p class="li-text">On-chain infrastructure development</p>
            <p class="li-text">Wallet in-game implementation</p>
            <p class="li-text">Web 3 pre-listing Quest</p>
          </div>
        </li>
        <li>
          <h1>December 2024</h1>
          <div>
            <p class="li-text">TGE</p>
            <p class="li-text">Token in-game utility launch</p>
          </div>
        </li>
        <li>
          <h1>Q3 2024</h1>
          <div>
            <p class="li-text">Squad Kombat</p>
            <p class="li-text">Characters and skins</p>
            <p class="li-text">Time limited events</p>
            <p class="li-text">Live events</p>
          </div>
        </li>
        <li>
          <h1>Q4 2024</h1>
          <div>
            <p class="li-text">
              Many more, we’ll keep in secret not to spoil your excitement
            </p>
          </div>
        </li>
      </ul>
      <div class="yan-jing">
        <img src="@/assets/pc/home/Container.png" alt="" />
      </div>
      <div class="yi-fu">
        <img src="@/assets/pc/home/Group%2025.png" alt="" />
      </div>
    </div>
    <div class="link-module">
      <a href="#">
        <img src="@/assets/pc/home/discord.png" alt="" />
      </a>
      <a href="#">
        <img src="@/assets/pc/home/telegram.png" alt="" />
      </a>
    </div>
  </div>
  <!--  icon图标库 symbol使用方法
  <svg class="icon" aria-hidden="true">
    <use xlink:href="#icon-xinxiang"></use>
    &lt;!&ndash; 替换为你的图标 id &ndash;&gt;
  </svg>-->
</template>

<script setup>
import { ref } from "vue";

let dialogVisible = ref();
let ph = ref("Enter your email");
</script>
